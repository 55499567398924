.loader {
      width: 100%;
      height: 100%;
      background: rgb(255,255,255,0.7);
}

.layer {      
      animation: scaleLogo 0.5s infinite;
}

.layer1 {
      transform-origin: 50% 50%; 
      animation-direction: alternate;
      animation-delay: 0.33s;
  }
  
  .layer2 {
      transform-origin: 50% 60%; 
      animation-direction: alternate;
    animation-delay: 0.16s;
  }
  
  .layer3 {
      transform-origin: 50% 75%; 
      animation-direction: alternate;
  
  }
  
  
  @keyframes scaleLogo {
    from {transform: scale(1.1)}
    to {transform: scale(1)}
  }