/* @import url(https://fonts.googleapis.com/css?family=Oswald); */

    .step {
      padding: 20px;
      border-bottom: 2px solid lightgray;
    }

    .button-confirm {
      background-color: rgba(0,114,206,1);
      color: white;
      /* margin: 20px; */
      height: 50px !important;
      padding: 13px;
      cursor: pointer;
    }

    .disabled {
      opacity: 0.5;
      cursor: default;
    }

    .filters svg {
      cursor: pointer;
    }

    /* Separator */
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
  }
  .separator::before, .separator::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #000;
  }
  .separator::before {
      margin-right: .25em;
  }
  .separator::after {
      margin-left: .25em;
  }

  .route-btn {
    cursor: pointer;
    background-color: rgba(0,114,206,1);
    margin: auto;
    color: white;
    padding: 10px;
  }