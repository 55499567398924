@import url(https://fonts.googleapis.com/css?family=Oswald);

.client-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: left 0.5s;
  background-color: white;
}

.tab-container  {
  position: fixed;
  bottom: 0;
  width: 20%;
  height: 5%;
}

.tabs {
  background-color: #f1f1f1;
  width: 100%;
}

  .sidebar-tab {
    width: 50%;
    height: 50px;
    margin: auto;
    padding: 13px;
  }

  .mode-active {
    background-color: #778beb;
    color: white
  }

  .search-input {
    /* background: url("../../assets/icons8-search.png") no-repeat scroll 95% center; */
    background-size: 1rem;
    background-color: #f2f2f2;
    /* background-origin: content-box; */
  }

