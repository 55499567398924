.FileUploader {
  color: darkgrey;
  border: 2px dashed darkgrey;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
}

.FileUploader:hover {
  color: rgba(0,114,206,1);
  border: 2px dashed rgba(0,114,206,1);
  transition: color 0.4s ease, border 0.4s ease;
}

.FileUploader:focus {
  color: rgba(0,114,206,1);
  border: 2px dashed rgba(0,114,206,1);
  transition: color 0.4s ease, border 0.4s ease;
}

.FileUploader input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  cursor: pointer;
}
