.trip-container {
      padding: 10px;
      border: 2px solid #0072ce;
      border-radius: 2px;
      margin: 10px;
      padding: 15px;
      cursor: pointer;
}

.photo-indicator {
      color: darkgray;
}

.photo-indicator img {
      margin-left: 5px;
}