.widget-container .widget {
      width: 50%;

      background-color:  rgba(0,114,206,1); 
      color: white;
      padding: 20px;
      /* margin: 0 15px 0 15px; */
      /* border-radius: px; */ 
}

.photo-upload-container {
      flex-basis: 0;
}

.upload-btn {
      background-color: rgba(0,114,206,1); 
      color: white;
      padding: 10px;
}

.cancel-btn {
      padding: 10px;
      border: 2px solid rgba(0,114,206,1);
}

.trip-desc a {
      color: rgba(0,114,206,1);
      font-weight: 800;
      /* text-decoration: underline; */
}